import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import SEO, { seoPropsMapping } from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import IframeResizer from "iframe-resizer-react"

const Roi = ({ data }) => {
  const section = data.prismicRoiPage.data
  const heroImage = section.hero_banner_image.fluid.src

  const seoProps = seoPropsMapping(data.prismicRoiPage.data)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header image={heroImage} title={section.hero_title.text} />
      <div id="root">
        <IframeResizer
          heightCalculationMethod="bodyScroll"
          src={section.iframe_link}
          style={{ width: "1px", minWidth: "100%" }}
        />
      </div>
    </Layout>
  )
}

export default withPreview(Roi)

export const roiQuery = graphql`
  {
    prismicRoiPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        custom_page_title
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_title {
          text
        }
        iframe_link
        body {
          ... on PrismicRoiPageBody2ColumnGeneric {
            id
            primary {
              column_one {
                html
              }
              column_two {
                text
              }
            }
            slice_type
            slice_label
          }
        }
        roi_feedback_section {
          item_content {
            html
          }
        }
      }
    }
  }
`
